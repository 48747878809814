body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;

  /* Disabling text selection */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
  .scrollable-content::-webkit-scrollbar {
    display: none;
  }

  .firebase-emulator-warning {
    display: none;
  }

  .react-datepicker__view-calendar-icon input {
    width: 75px;
  }

  .rti--tag {
    color: white;
    background-color: #b40000;
    padding-left: 10px;
  }

  .rti--tag > button {
    color: white;
  }

  .custom-date-picker-input {
    border: 1px solid black;
    height: 22px;
    border-radius: 4px; 
  }

  .custom-date-picker-input:focus-visible { 
    border: 2px solid #b40000; 
    outline: none;
  }
  
  .react-datepicker__day--selected {
    background-color: #b40000;
  }

  .react-datepicker__calendar-icon {
    padding: 9px 8px 6px 7px !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
