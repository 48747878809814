/* HTML: <div class="loader"></div> */
.loader2  {
  width: fit-content;
  font-family: Ultra, serif;
  font-size: 24px;
  /* animation: l25 1s infinite; */
  color: #b40000;
}
.loader2::before {
  content:"Knock";
}

@keyframes l25{
  0%,12.5% {transform: translate(10px,0)}
  13%,25%  {transform: translate(4px,-4px)}
  26%,37.5%{transform: translate(2px,8px)}
  38%,50%  {transform: translate(12px,-6px)}
  51%,62.5%{transform: translate(0,12px)}
  63%,75%  {transform: translate(-8px,-4px)}
  76%,86.5%{transform: translate(-12px,6px)}
  87%,100% {transform: translate(6px,0)}
}

/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: bold;
  font-size: 26px;
  background: radial-gradient(circle closest-side, #000 94%, #0000) right/calc(190% - 1em) 100%;
  animation: l24 0.4s infinite alternate linear;
}
.loader::before {
  content: "My Door";
  line-height: 1.5em;
  color: #0000;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 91%, #000);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l24 {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
